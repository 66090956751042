import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`On February of 2023, `}<a parentName="p" {...{
        "href": "https://www.credly.com/badges/eeb5d1f4-d326-414f-a594-932b74ad5889/public_url"
      }}>{`I earned`}</a>{` the information security certificate `}<a parentName="p" {...{
        "href": "https://www.comptia.org/certifications/security"
      }}>{`CompTIA Security+`}</a>{`, which represents my significant knowledge in personal, enterprise, application, and cloud security.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.credly.com/badges/eeb5d1f4-d326-414f-a594-932b74ad5889/public_url"
      }}><img style={{
          "cssFloat": "left",
          "marginLeft": "-20px",
          "paddingRight": "20px"
        }} src="/images/certs/comptia-security-ce-certification.png" /></a></p>
    <p>{`According to `}<a parentName="p" {...{
        "href": "https://www.comptia.org/certifications/security"
      }}>{`CompTIA`}</a>{`, this verifies my ability to:`}</p>
    <blockquote>
      <ul parentName="blockquote">
        <li parentName="ul">{`Assess the security posture of an enterprise environment and recommend and implement appropriate security solutions.`}</li>
        <li parentName="ul">{`Monitor and secure hybrid environments, including cloud, mobile, Internet of Things (IoT), and operational technology.`}</li>
        <li parentName="ul">{`Operate with an awareness of applicable regulations and policies, including principles of governance, risk, and compliance.`}</li>
        <li parentName="ul">{`Identify, analyze, and respond to security events and incidents.`}</li>
      </ul>
    </blockquote>
    <div style={{
      "clear": "left"
    }} />
    <p>{`I don't work in information security specifically, but I believe a strong knowledge of information security principles is critical to modern
software engineering, `}<a parentName="p" {...{
        "href": "/#web"
      }}>{`especially for the web`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      